<template>
  <v-img
    src="../assets/ops-logo.svg"
    :contain="true"
    :height="responsiveValue"
    :width="responsiveValue"
  />
</template>

<script>
export default {
  name: 'UILogo',
  computed: {
    responsiveValue() {
      return this.$vuetify.breakpoint.smAndDown ? 32 : 48;
    },
  },
};
</script>
