import axios from 'axios';
import interceptor from './interceptors';
import { isValidUUID } from '../util/uuid';

const vdrRegistry = axios.create({
  baseURL: process.env.VUE_APP_VDR_REGISTRY,
  timeout: 8000,
});

vdrRegistry.interceptors.request.use(...interceptor.request);

const baseUrl = 'ops';

export default {
  async getProductInstances(page, size, identifier) {
    let route = `${baseUrl}/instances?page=${page}&size=${size}`;

    if (identifier) {
      if (isValidUUID(identifier)) {
        route += `&instanceId=${identifier}`;
      } else {
        route += `&instanceName=${identifier}`;
      }
    }

    const response = await vdrRegistry.get(route);
    return response;
  },
  async getSubscriptions(page, size, productInstanceIds) {
    let route = `${baseUrl}/subscriptions?page=${page}&size=${size}`;

    if (productInstanceIds) {
      route += '&productInstanceIds=';
      for (let i = 0; i < productInstanceIds.length; i += 1) {
        route += `${productInstanceIds[i]}`;
        if ((i + 1) < productInstanceIds.length) {
          route += ',';
        }
      }
    }

    const response = await vdrRegistry.get(route);
    return response;
  },
  async getProductInstanceConfiguration(productInstanceId) {
    const response = await vdrRegistry.get(`${baseUrl}/instances/details/${productInstanceId}`);
    return response;
  },
  // Not neeeded as we get the subscriptions within
  // getProductInstanceConfiguration
  async getProductSubscriptions(productInstanceId) {
    const response = await vdrRegistry.get(`${baseUrl}/productinstance/${productInstanceId}/subscriptions`);
    return response;
  },
  async getSubscriptionConfiguration(subscriptionId, subscriptionChannel) {
    const response = await vdrRegistry.get(`${baseUrl}/subscriptions/details/${subscriptionId}/${subscriptionChannel}`);
    return response;
  },
  async updateProductInstanceConfiguration(body, productInstanceId) {
    const response = await vdrRegistry.put(`${baseUrl}/instances/${productInstanceId}`, body);
    return response;
  },
  async updateSubscriptionConfiguration(subscription) {
    const response = await vdrRegistry.put(`${baseUrl}/subscriptions/${subscription.subscriptionId}/${subscription.channel}`,
      subscription);
    return response;
  },
  async getSupportedRtpConfigurations() {
    const response = await vdrRegistry.get(`${baseUrl}/rtp-configurations`);
    return response;
  },
  async getSupportedPayInTaxes() {
    const response = await vdrRegistry.get('taxes?taxType=PAYIN');
    return response;
  },
  async getSupportedPayOutTaxes() {
    const response = await vdrRegistry.get('taxes?taxType=PAYOUT');
    return response;
  },
  async stopSubscription(subscriptionId) {
    const response = await vdrRegistry.put(`tenants/subscriptions/${subscriptionId}/actions/deactivate`);
    return response;
  },
  async startSubscription(subscriptionId) {
    const response = await vdrRegistry.put(`tenants/subscriptions/${subscriptionId}/actions/activate`);
    return response;
  },
};
