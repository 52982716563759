import { assign } from 'lodash';

export default {
  path: '/gis',
  component: () => import(/* webpackChunkName: "OPS" */'../layouts/TemplateDefault'),
  children: [
    {
      path: '',
      name: 'GIS',
      component: () => import('../pages/GISDashboard'),
    },
    {
      path: ':platformAccountUuid/details',
      name: 'GISDetails',
      component: () => import('../pages/GISDetails'),
      beforeEnter: (to, from, next) => {
        assign(to.meta, {
          exactPath: to.path,
        });
        next();
      },
      meta: {
        breadCrumb: 'platformAccountDetails',
        info: 'installationRule',
      },
    },
  ],
  meta: {
    icon: 'mdi-toolbox-outline',
    breadCrumb: 'gis',
  },
};
