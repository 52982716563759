import axios from 'axios';
import interceptor from './interceptors';
import { isValidUUID } from '../util/uuid';

const cpr = axios.create({
  baseURL: process.env.VUE_APP_CENTRAL_PRODUCT_REGISTRY,
  timeout: 8000,
});

cpr.interceptors.request.use(...interceptor.request);

export default {
  async stopSubscription(subscriptionId) {
    const response = await cpr.put(`ops/subscriptions/actions/deactivate/${subscriptionId}`);
    return response;
  },
  async startSubscription(subscriptionId) {
    const response = await cpr.put(`ops/subscriptions/actions/activate/${subscriptionId}`);
    return response;
  },
  async getSevenProductOrders(page, size, identifier, status) {
    let route = `ops/orders?page=${page}&size=${size}`;

    if (identifier) {
      if (isValidUUID(identifier)) {
        route += `&productInstanceId=${identifier}`;
      } else {
        route += `&productInstanceName=${identifier}`;
      }
    }

    if (status) {
      route += `&productStatus=${status}`;
    }

    const response = await cpr.get(route);
    return response;
  },
  async getSevenProductOrderByUuid(productInstanceId) {
    const response = cpr.get(`ops/orders/details/${productInstanceId}`);
    return response;
  },
  async getProductDeployments(productName) {
    const response = cpr.get(`products/deployments/${productName}`);
    return response;
  },
  async installProductOrder(productInstanceId, productDeploymentId) {
    const response = cpr.put(`ops/orders/actions/install?sevenProductId=${productInstanceId}&productDeploymentId=${productDeploymentId}`);
    return response;
  },
};
