import api from '../../../api';
import types from './mutationTypes';
import rootTypes from '../../mutationTypes';
import rootState from '../../state';

export default {
  async getProductInstances({ commit, dispatch }, payload) {
    try {
      const response = await api.vdrRegistry.getProductInstances(
        payload.page,
        payload.size,
        payload.identifier,
      );
      commit(types.SET_PRODUCT_INSTANCES, response.data);
      return response.data.data;
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
  },
  async getSubscriptions({ commit, dispatch }, payload) {
    try {
      const response = await api.vdrRegistry.getSubscriptions(
        payload.page,
        payload.size,
        payload.productInstanceIds,
      );
      commit(types.SET_SUBSCRIPTIONS, response.data);
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
  },
  async getProductInstanceConfiguration({ commit, dispatch }, payload) {
    commit(rootTypes.SET_LOADING, true, { root: true });
    commit(types.SET_PRODUCT_INSTANCE_CONFIGURATION, undefined);
    try {
      const response = await api.vdrRegistry
        .getProductInstanceConfiguration(payload.productInstanceId);
      commit(types.SET_PRODUCT_INSTANCE_CONFIGURATION, response.data);
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    } finally {
      commit(rootTypes.SET_LOADING, false, { root: true });
    }
  },
  async getProductSubscriptions({ commit, dispatch }, payload) {
    try {
      const response = await api.vdrRegistry
        .getProductSubscriptions(payload.productInstanceId);
      commit(types.SET_PRODUCT_SUBSCRIPTIONS, response.data);
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
  },
  async getSubscriptionConfiguration({ commit, dispatch }, payload) {
    commit(rootTypes.SET_LOADING, true, { root: true });
    commit(types.SET_SUBSCRIPTION_CONFIGURATION, undefined);
    try {
      const response = await api.vdrRegistry
        .getSubscriptionConfiguration(payload.subscriptionId, payload.subscriptionChannel);
      commit(types.SET_SUBSCRIPTION_CONFIGURATION, { ...response.data, channel: payload.subscriptionChannel });
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    } finally {
      commit(rootTypes.SET_LOADING, false, { root: true });
    }
  },
  removeVDRConfigDetails({ commit }) {
    commit(types.SET_PRODUCT_INSTANCE_CONFIGURATION, undefined);
    commit(types.SET_SUBSCRIPTION_CONFIGURATION, undefined);
    commit(types.SET_PRODUCT_SUBSCRIPTIONS, undefined);
  },
  async saveProductInstanceConfiguration({ commit, dispatch }, payload) {
    commit(rootTypes.SET_LOADING, true, { root: true });
    try {
      await api.vdrRegistry.updateProductInstanceConfiguration(payload.body, payload.productInstanceId);
      commit(types.SET_PRODUCT_INSTANCE_CONFIGURATION, payload.body);
      commit(rootTypes.SHOW_NOTIFICATION, {
        type: rootState.notificationTypes.SUCCESS,
      }, { root: true });
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    } finally {
      commit(rootTypes.SET_LOADING, false, { root: true });
    }
  },
  async saveSubscriptionConfiguration({ commit, dispatch }, payload) {
    commit(rootTypes.SET_LOADING, true, { root: true });
    try {
      await api.vdrRegistry.updateSubscriptionConfiguration(payload);
      commit(types.SET_SUBSCRIPTION_CONFIGURATION, payload);
      commit(rootTypes.SHOW_NOTIFICATION, {
        type: rootState.notificationTypes.SUCCESS,
      }, { root: true });
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    } finally {
      commit(rootTypes.SET_LOADING, false, { root: true });
    }
  },
  // async getSupportedRtpConfigurations({ commit, dispatch }) {
  //   try {
  //     const response = await api.vdrRegistry.getSupportedRtpConfigurations();
  //     commit(types.SET_SUPPORTED_RTP_CONFIGURATIONS, response.data);
  //   } catch (error) {
  //     dispatch('handleRestError', error, { root: true });
  //   }
  // },
  async getSupportedPayOutTaxes({ commit, dispatch }) {
    try {
      const response = await api.vdrRegistry.getSupportedPayOutTaxes();
      commit(types.SET_SUPPORTED_PAYOUT_TAXES, response.data);
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
  },
  async getSupportedPayInTaxes({ commit, dispatch }) {
    try {
      const response = await api.vdrRegistry.getSupportedPayInTaxes();
      commit(types.SET_SUPPORTED_PAYIN_TAXES, response.data);
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
  },
  async startSubscription({ commit, dispatch }, payload) {
    commit(rootTypes.SET_LOADING, true, { root: true });
    try {
      await api.centralProductRegistry.startSubscription(payload.subscriptionId);
      commit(rootTypes.SHOW_NOTIFICATION, {
        type: rootState.notificationTypes.SUCCESS,
      }, { root: true });
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    } finally {
      commit(rootTypes.SET_LOADING, false, { root: true });
    }
  },
  async stopSubscription({ commit, dispatch }, payload) {
    commit(rootTypes.SET_LOADING, true, { root: true });
    try {
      await api.centralProductRegistry.stopSubscription(payload.subscriptionId);
      commit(rootTypes.SHOW_NOTIFICATION, {
        type: rootState.notificationTypes.SUCCESS,
      }, { root: true });
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    } finally {
      commit(rootTypes.SET_LOADING, false, { root: true });
    }
  },
  setSubscriptionConfiguration({ commit }, payload) {
    commit(types.SET_SUBSCRIPTION_CONFIGURATION, payload);
  },
};
