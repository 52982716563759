export default {
  installationStatuses: [
    { value: 'pending', text: 'Pending' },
    { value: 'configured', text: 'Configured' }],
  tableHeaders: [
    { translationKey: 'uuid', value: 'uuid' },
    { translationKey: 'name', value: 'name' },
    { translationKey: 'actions', value: 'actions' }],
  platformAccounts: {
    data: [],
    pagination: {
      page: 1,
      size: 10,
      total: 0,
    },
  },
  currentInstallation: undefined,
};
