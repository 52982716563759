import api from '../../../api';
import types from './mutationTypes';
import rootTypes from '../../mutationTypes';
import rootState from '../../state';

export default {
  async getJackpotConfigs({ commit, dispatch }, payload) {
    try {
      const response = await api.advancedJackpotSystem
        .getJackpotConfigs(payload.page, payload.size, payload.identifier);
      commit(types.SET_JACKPOT_CONFIGS, response.data);
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
  },
  async getJackpotConfigByUuid({ commit, dispatch }, payload) {
    commit(rootTypes.SET_LOADING, true, { root: true });
    commit(types.SET_JACKPOT_CONFIG_DETAILS, {
      uuid: undefined,
      name: undefined,
      displayName: undefined,
      description: undefined,
      enabled: true,
      dataCenter: 'dc1_london',
      currency: 'EUR',
      primaryRatio: 60,
      secondaryRatio: 40,
      betshopSmallestUnit: false,
      fundConfigs: [],
      productConfigs: [],
      pools: [],
    });
    try {
      const response = await api.advancedJackpotSystem.getJackpotConfigByUuid(payload);
      commit(types.SET_JACKPOT_CONFIG_DETAILS, response.data);
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
    commit(rootTypes.SET_LOADING, false, { root: true });
  },
  async saveConfig({ commit, dispatch }, payload) {
    commit(rootTypes.SET_LOADING, true, { root: true });
    try {
      await api.advancedJackpotSystem.updateJackpot(payload);
      commit(rootTypes.SHOW_NOTIFICATION, {
        type: rootState.notificationTypes.SUCCESS,
      }, { root: true });
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
    commit(rootTypes.SET_LOADING, false, { root: true });
  },
  async createConfig({ commit, dispatch }, payload) {
    commit(rootTypes.SET_LOADING, true, { root: true });
    try {
      await api.advancedJackpotSystem.createJackpot(payload);
      commit(rootTypes.SHOW_NOTIFICATION, {
        type: rootState.notificationTypes.SUCCESS,
      }, { root: true });
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
    commit(rootTypes.SET_LOADING, false, { root: true });
  },
  getTicketSendingStatus(_, payload) {
    return new Promise((resolve, reject) => {
      api.advancedJackpotSystem.getAJSTicketToggleStatus(payload)
        .then(resolve)
        .catch(reject);
    });
  },
  async getSupportedProducts({ commit, dispatch }) {
    try {
      const response = await api.advancedJackpotSystem.getEnabledProducts();
      commit(types.SET_SUPPORTED_PRODUCTS, response.data);
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
  },
  addNewFundConfig({ commit }) {
    commit(types.ADD_NEW_FUND_CONFIG, {
      priorityId: undefined,
      fundNumber: undefined,
      name: undefined,
      displayName: undefined,
      lowerLimit: undefined,
      upperLimit: undefined,
      payinLimit: 0,
      transferredLimit: undefined,
      transferredAmount: undefined,
      temporalLowerLimit: undefined,
      temporalUpperLimit: undefined,
      minimumStart: undefined,
      displayTime: 20,
      jackpotType: 0,
      locationBased: false,
      hasImages: false,
      showPreviousJackpots: false,
      showLocationDetails: false,
      showLastJackpot: false,
      waitForCountdown: false,
      displayColor: undefined,
      secondaryColor: undefined,
      textColorOne: undefined,
      textColorTwo: undefined,
    });
  },
  addNewPool({ commit }) {
    commit(types.ADD_NEW_POOL, {
      id: crypto.randomUUID(),
      accountId: undefined,
      fundNumber: undefined,
      name: undefined,
      lowerLimit: undefined,
      upperLimit: undefined,
      transferredLimit: undefined,
      transferredAmount: undefined,
      temporalLowerLimit: undefined,
      temporalUpperLimit: undefined,
      minimumStart: undefined,
      locations: [],
      isNewPool: true, // todo:: check where this is used
    });
  },
  addNewProductConfig({ commit }, payload) {
    const newProductConfig = {
      accountId: undefined,
      channel: undefined,
      contribution: undefined,
      fundNumber: payload,
      gameId: undefined,
      productName: undefined,
      contributionColor: undefined,
    };

    commit(types.ADD_NEW_PRODUCT_CONFIG, newProductConfig);
    commit(types.CALCULATE_CONTRIBUTION_COLOR, newProductConfig);
  },
  addNewLocation({ commit }, payload) {
    commit(types.ADD_NEW_LOCATION, {
      poolId: payload,
      location: {
        id: undefined,
      },
    });
  },
  deleteFundConfig({ commit }, payload) {
    commit(types.DELETE_FUND_CONFIG, payload);
  },
  deleteProductConfig({ commit }, payload) {
    commit(types.DELETE_PRODUCT_CONFIG, payload);
  },
  deletePool({ commit }, payload) {
    commit(types.DELETE_POOL, payload);
  },
  deleteLocation({ commit }, payload) {
    commit(types.DELETE_LOCATION, payload);
  },
  calculateContributionColor({ commit }, payload) {
    commit(types.CALCULATE_CONTRIBUTION_COLOR, payload);
  },
  clearCurrentJackpotConfig({ commit }) {
    commit(types.SET_JACKPOT_CONFIG_DETAILS, {
      uuid: undefined,
      name: undefined,
      displayName: undefined,
      description: undefined,
      enabled: true,
      dataCenter: 'dc1_london',
      currency: 'EUR',
      primaryRatio: 60,
      secondaryRatio: 40,
      betshopSmallestUnit: false,
      fundConfigs: [],
      productConfigs: [],
      pools: [],
    });
  },
};
