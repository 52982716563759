import api from '../../../api';
import types from './mutationTypes';
import rootTypes from '../../mutationTypes';
import rootState from '../../state';

export default {
  async getSevenProductOrders({ commit, dispatch }, payload) {
    try {
      const response = await api.centralProductRegistry.getSevenProductOrders(
        payload.page,
        payload.size,
        payload.identifier,
        payload.status,
      );
      commit(types.SET_SEVEN_PRODUCT_ORDERS, response.data);
      return response.data.data;
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
  },
  async getSevenProductOrderByUuid({ commit, dispatch }, payload) {
    commit(rootTypes.SET_LOADING, true, { root: true });
    try {
      const response = await api.centralProductRegistry.getSevenProductOrderByUuid(payload);
      commit(types.SET_SEVEN_PRODUCT_ORDER_DETAILS, response.data);
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
    commit(rootTypes.SET_LOADING, false, { root: true });
  },
  async getProductDeployments({ commit, dispatch }, payload) {
    commit(rootTypes.SET_LOADING, true, { root: true });
    try {
      const response = await api.centralProductRegistry.getProductDeployments(payload);
      commit(types.SET_PRODUCT_DEPLOYMENTS, response.data);
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
    commit(rootTypes.SET_LOADING, false, { root: true });
  },
  async installProductOrder({ commit, dispatch }, payload) {
    commit(rootTypes.SET_LOADING, true, { root: true });
    try {
      const response = await api.centralProductRegistry.installProductOrder(
        payload.sevenProductId,
        payload.productDeploymentId,
      );
      commit(types.SET_PRODUCT_DEPLOYMENTS, response.data);
      commit(types.SET_CURRENT_PRODUCT_AS_INSTALLED);
      commit(rootTypes.SHOW_NOTIFICATION, {
        type: rootState.notificationTypes.SUCCESS,
      }, { root: true });
    } catch (error) {
      dispatch('handleRestError', error, { root: true });
    }
    commit(rootTypes.SET_LOADING, false, { root: true });
  },
  removeSevenProductOrders({ commit }) {
    commit(types.SET_SEVEN_PRODUCT_ORDERS, undefined);
  },
  removeSevenProductOrder({ commit }) {
    commit(types.SET_SEVEN_PRODUCT_ORDER_DETAILS, undefined);
  },
  removeProductDeployments({ commit }) {
    commit(types.SET_PRODUCT_DEPLOYMENTS, undefined);
  },
};
