import { assign } from 'lodash';

export default {
  path: '/ajs',
  component: () => import(/* webpackChunkName: "OPS" */'../layouts/TemplateDefault'),
  children: [
    {
      path: '',
      name: 'AJS',
      component: () => import('../pages/AJSDashboard'),
    },
    {
      path: 'create',
      name: 'AJSCreate',
      component: () => import('../pages/AJSCreate'),
      meta: {
        breadCrumb: 'newJackpot',
      },
    },
    {
      path: ':jackpotConfigUuid/details',
      name: 'AJSDetails',
      component: () => import('../pages/AJSDetails'),
      beforeEnter: (to, from, next) => {
        assign(to.meta, {
          exactPath: to.path,
        });
        next();
      },
      meta: {
        breadCrumb: 'jackpotDetails',
      },
    },
    {
      path: ':jackpotConfigUuid/edit',
      name: 'AJSEdit',
      component: () => import('../pages/AJSEdit'),
      beforeEnter: (to, from, next) => {
        assign(to.meta, {
          exactPath: to.path,
        });
        next();
      },
      meta: {
        breadCrumb: 'editJackpot',
      },
    },
  ],
  meta: {
    icon: 'mdi-clover',
    breadCrumb: 'ajs',
  },
};
