import Vue from 'vue';
import Router from 'vue-router';
import GamesOps from '../pages/GamesOps';
import AdvancedJackpotSystem from './ajs';
import GamesInstallService from './gis';
import CrashCashRegistry from './ccr';
import CentralProductRegistry from './cpr';
import RouletteConfig from './rc';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'GamesOps',
      component: GamesOps,
      children: [
        AdvancedJackpotSystem,
        GamesInstallService,
        CentralProductRegistry,
        CrashCashRegistry,
        RouletteConfig,
      ],
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/',
    },
  ],
});
