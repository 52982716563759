import { isValidUUID } from '../util/uuid';
import http from './http';

const baseUrl = 'gis';

export default {
  async getPlatformAccounts(page, size, identifier, status) {
    let route = `${baseUrl}/platformaccount?page=${page}&size=${size}`;

    if (identifier) {
      if (isValidUUID(identifier)) {
        route += `&uuid=${identifier}`;
      } else {
        route += `&name=${identifier}`;
      }
    }

    if (status) {
      route += `&status=${status}`;
    }

    const response = await http.get(route);
    return response;
  },
  async getPlatformAccountInstallationByUuid(platformAccountUuid) {
    const response = await http.get(`${baseUrl}/installation/${platformAccountUuid}`);
    return response;
  },
  async installPlatformAccount(platformAccountUuid, platformHttpRouteId, platformProviderId) {
    const response = await http.put(`${baseUrl}/install/platform`, {
      platformAccountUuid,
      serviceAccountSubscriberHttpRouteId: platformHttpRouteId,
      platformProviderId,
    });
    return response;
  },
  async installServiceAccountSubscription(serviceAccountSubscriptionUuid, serviceHttpRouteId) {
    const response = await http.put(`${baseUrl}/install/serviceaccountsubscription`, {
      serviceAccountSubscriptionUuid,
      serviceHttpRouteId,
    });
    return response;
  },
};
