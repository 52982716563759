import { find } from 'lodash';
import types from './mutationTypes';

export default {
  [types.SET_PLATFORM_ACCOUNTS](state, data) {
    if (data) {
      state.platformAccounts.data = data.data;
      state.platformAccounts.pagination = data.pagination;
    } else {
      state.platformAccounts.data = undefined;
      state.platformAccounts.pagination = undefined;
    }
  },
  [types.SET_PLATFORM_ACCOUNT_INSTALLATION_DETAILS](state, data) {
    if (!data) {
      state.currentInstallation = undefined;
      return;
    }

    state.currentInstallation = data;
  },
  [types.SET_PLATFORM_ACCOUNT_INSTALLATION_STATUS](state, data) {
    if (!state.currentInstallation) {
      return;
    }

    state.currentInstallation.platformAccount.isInstalled = data;
  },
  [types.SET_SERVICE_ACCOUNT_SUBSCRIPTION_INSTALLATION_STATUS](state, data) {
    if (!state.currentInstallation) {
      return;
    }

    const { serviceAccountSubscriptions } = state.currentInstallation;
    const uuid = data.serviceAccountSubscriptionUuid;
    const serviceAccountSubscription = find(serviceAccountSubscriptions, { uuid });
    if (serviceAccountSubscription) {
      serviceAccountSubscription.isInstalled = data.status;
    }
  },
};
