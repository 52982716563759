import { assign } from 'lodash';

export default {
  path: '/crash-cash-config',
  component: () => import(/* webpackChunkName: "OPS" */'../layouts/TemplateDefault'),
  children: [
    {
      path: '',
      name: 'CCRDashboard',
      component: () => import('../pages/CCRDashboard'),
    },
    {
      path: ':productInstanceId/details',
      name: 'CCRProductInstanceDetails',
      component: () => import('../pages/CCRDetails'),
      beforeEnter: (to, from, next) => {
        assign(to.meta, {
          exactPath: to.path,
        });
        next();
      },
      meta: {
        breadCrumb: 'productInstanceDetails',
      },
    },
    {
      path: ':subscriptionId/:subscriptionChannel/details',
      name: 'CCRSubscriptionDetails',
      component: () => import('../pages/CCRDetails'),
      beforeEnter: (to, from, next) => {
        assign(to.meta, {
          exactPath: to.path,
        });
        next();
      },
      meta: {
        breadCrumb: 'subscriptionDetails',
      },
    },
    {
      path: ':productInstanceId/edit',
      name: 'CCRProductInstanceEdit',
      component: () => import('../pages/CCREdit'),
      beforeEnter: (to, from, next) => {
        assign(to.meta, {
          exactPath: to.path,
        });
        next();
      },
      meta: {
        breadCrumb: 'productInstanceConfig',
      },
    },
    {
      path: ':subscriptionId/:subscriptionChannel/edit',
      name: 'CCRSubscriptionEdit',
      component: () => import('../pages/CCREdit'),
      beforeEnter: (to, from, next) => {
        assign(to.meta, {
          exactPath: to.path,
        });
        next();
      },
      meta: {
        breadCrumb: 'subscriptionConfig',
      },
    },
  ],
  meta: {
    imageUrl: '../assets/crash-cash-config-logo.png',
    imageClass: 'crash-cash-logo',
    breadCrumb: 'crashCashConfig',
    isImage: true,
    icon: 'mdi-cog',
  },
};
