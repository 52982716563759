<template>
  <v-navigation-drawer
    app
    permanent
    expand-on-hover
    dark
    color="primary"
    :mini-variant.sync="mini"
  >
    <v-list nav dense>
      <v-list-item class="mb-5">
        <UILogo />
      </v-list-item>
      <v-list-item-group v-model="group" active-class="primary">
        <v-list-item
          v-for="item in navbarItems"
          :key="item.name"
          link
          :to="item.path"
        >
          <v-list-item-icon>
            <div v-if="item.meta.isImage"
              :class="item.meta.imageClass"
              :contain="true"></div>
            <v-icon v-else>{{ item.meta.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ $vuetify.lang.t(`$vuetify.${item.meta.breadCrumb}`) }}
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { find } from 'lodash';
import UILogo from './UILogo';

export default {
  name: 'MainNavigation',
  data() {
    return {
      group: null,
      mini: true,
    };
  },
  components: {
    UILogo,
  },
  computed: {
    isSmAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isMdAndUp() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    navbarItems() {
      return find(this.$router.options.routes, { name: 'GamesOps' }).children;
    },
  },
};
</script>

<style lang="scss">
.crash-cash-logo {
  background-image: url('~@/assets/crash-cash-config-logo.png');
  width: 25px;
  height: 25px;
  background-size: 24px;
}

.vdr-logo {
  background-image: url('~@/assets/vdr-config-logo.png');
  width: 25px;
  height: 25px;
  background-size: 28px;
  background-position: center;
}
</style>
