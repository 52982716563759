export default {
  SET_JACKPOT_CONFIGS: 'SET_JACKPOT_CONFIGS',
  SET_JACKPOT_CONFIG_DETAILS: 'SET_JACKPOT_CONFIG_DETAILS',
  SET_SUPPORTED_PRODUCTS: 'SET_SUPPORTED_PRODUCTS',
  ADD_NEW_FUND_CONFIG: 'ADD_NEW_FUND_CONFIG',
  ADD_NEW_POOL: 'ADD_NEW_POOL',
  ADD_NEW_PRODUCT_CONFIG: 'ADD_NEW_PRODUCT_CONFIG',
  ADD_NEW_LOCATION: 'ADD_NEW_LOCATION',
  DELETE_FUND_CONFIG: 'DELETE_FUND_CONFIG',
  DELETE_PRODUCT_CONFIG: 'DELETE_PRODUCT_CONFIG',
  DELETE_POOL: 'DELETE_POOL',
  DELETE_LOCATION: 'DELETE_LOCATION',
  CALCULATE_CONTRIBUTION_COLOR: 'CALCULATE_CONTRIBUTION_COLOR',
  CALCULATE_CONTRIBUTION_COLORS: 'CALCULATE_CONTRIBUTION_COLORS',
};
