import { isValidUUID } from '../util/uuid';
import http from './http';

export default {
  async getJackpotConfigs(page, size, identifier) {
    let route = `ajs/jackpotconfig/?page=${page}&size=${size}`;

    if (identifier) {
      if (isValidUUID(identifier)) {
        route += `&uuid=${identifier}`;
      } else {
        route += `&name=${identifier}`;
      }
    }

    const response = await http.get(route);
    return response;
  },
  async getJackpotConfigByUuid(uuid) {
    const response = await http.get(`ajs/jackpotconfig/${uuid}`);
    return response;
  },
  async createJackpot(jackpot) {
    const response = await http.post('ajs/jackpotconfig', jackpot);
    return response;
  },
  async updateJackpot(jackpot) {
    const response = await http.patch('ajs/jackpotconfig', jackpot);
    return response;
  },
  async getEnabledProducts() {
    const response = await http.get('ajs/products');
    return response;
  },
  async getAJSTicketToggleStatus(cpvUUID) {
    const route = `ngs/ticket-sending-status?gameUUID=${cpvUUID}`;
    const response = await http.get(route);
    return response;
  },
};
