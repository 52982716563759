import auth from './auth';
import advancedJackpotSystem from './ajs';
import gamesInstallService from './gis';
import crashCashRegistry from './ccr';
import centralProductRegistry from './cpr';
import vdrRegistry from './vdrr';
import rouletteConfig from './rc';

export default {
  auth,
  advancedJackpotSystem,
  gamesInstallService,
  crashCashRegistry,
  vdrRegistry,
  centralProductRegistry,
  rouletteConfig,
};
