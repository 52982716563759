export default {
  orderStatuses: [
    { value: 'ACTION_REQUIRED', text: 'Action required' },
    { value: 'PENDING', text: 'Pending' },
    { value: 'CONFIRMED', text: 'Confirmed' },
    { value: 'REJECTED', text: 'Rejected' },
  ],
  tableHeaders: [
    { translationKey: 'uuid', value: 'uuid' },
    { translationKey: 'name', value: 'displayId' },
    { translationKey: 'version', value: 'versionDisplayId' },
    { translationKey: 'status', value: 'status' },
    { translationKey: 'actions', value: 'actions' },
  ],
  sevenProductOrders: {
    data: [],
    pagination: {
      page: 1,
      size: 10,
      total: 0,
    },
  },
  currentProductOrder: undefined,
  currentProductDeployments: undefined,
};
