import axios from 'axios';
import interceptor from './interceptors';
import { isValidUUID } from '../util/uuid';

const crashCashRegistry = axios.create({
  baseURL: process.env.VUE_APP_CRASH_CASH_REGISTRY,
  timeout: 8000,
});

crashCashRegistry.interceptors.request.use(...interceptor.request);

const baseUrl = 'configuration';

export default {
  async getProductInstances(page, size, identifier) {
    let route = `${baseUrl}/productinstances?page=${page}&size=${size}`;

    if (identifier) {
      if (isValidUUID(identifier)) {
        route += `&productInstanceId=${identifier}`;
      } else {
        route += `&productInstanceName=${identifier}`;
      }
    }

    const response = await crashCashRegistry.get(route);
    return response;
  },
  async getSubscriptions(page, size, productInstanceIds) {
    let route = `${baseUrl}/subscriptions?page=${page}&size=${size}`;

    if (productInstanceIds) {
      route += '&productInstanceIds=';
      for (let i = 0; i < productInstanceIds.length; i += 1) {
        route += `${productInstanceIds[i]}`;
        if ((i + 1) < productInstanceIds.length) {
          route += ',';
        }
      }
    }

    const response = await crashCashRegistry.get(route);
    return response;
  },
  async getProductInstanceConfiguration(productInstanceId) {
    const response = await crashCashRegistry.get(`${baseUrl}/productinstance-details/${productInstanceId}`);
    return response;
  },
  async getProductSubscriptions(productInstanceId) {
    const response = await crashCashRegistry.get(`${baseUrl}/productinstance/${productInstanceId}/subscriptions`);
    return response;
  },
  async getSubscriptionConfiguration(subscriptionId, subscriptionChannel) {
    const response = await crashCashRegistry.get(`${baseUrl}/subscription-details/${subscriptionId}/${subscriptionChannel}`);
    return response;
  },
  async updateProductInstanceConfiguration(productInstance) {
    const response = await crashCashRegistry.put(`${baseUrl}/productinstance`, productInstance);
    return response;
  },
  async updateSubscriptionConfiguration(subscription) {
    const response = await crashCashRegistry.put(`${baseUrl}/subscription`, subscription);
    return response;
  },
  async getSupportedRtpConfigurations() {
    const response = await crashCashRegistry.get(`${baseUrl}/rtp-configurations`);
    return response;
  },
  async getSupportedPayInTaxes() {
    const response = await crashCashRegistry.get('taxes?taxType=PAYIN');
    return response;
  },
  async getSupportedPayOutTaxes() {
    const response = await crashCashRegistry.get('taxes?taxType=PAYOUT');
    return response;
  },
  async stopSubscription(subscriptionId) {
    const response = await crashCashRegistry.put(`tenants/subscriptions/${subscriptionId}/actions/deactivate`);
    return response;
  },
  async startSubscription(subscriptionId) {
    const response = await crashCashRegistry.put(`tenants/subscriptions/${subscriptionId}/actions/activate`);
    return response;
  },
};
