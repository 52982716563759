import { assign } from 'lodash';

export default {
  path: '/central-product-registry',
  component: () => import(/* webpackChunkName: "OPS" */'../layouts/TemplateDefault'),
  children: [
    {
      path: '',
      name: 'CPR',
      component: () => import('../pages/CPRDashboard'),
    },
    {
      path: ':productInstanceId/details',
      name: 'CPRDetails',
      component: () => import('../pages/CPRDetails'),
      beforeEnter: (to, from, next) => {
        assign(to.meta, {
          exactPath: to.path,
        });
        next();
      },
      meta: {
        breadCrumb: 'orderStatusDetails',
      },
    },
  ],
  meta: {
    icon: 'mdi-toolbox-outline',
    breadCrumb: 'cpr',
  },
};
