export default {
  path: '/roulette-config',
  component: () => import(/* webpackChunkName: "OPS" */'../layouts/TemplateDefault'),
  children: [
    {
      path: '',
      name: 'RCDashboard',
      component: () => import('../pages/RCDashboard'),
    },
  ],
  meta: {
    breadCrumb: 'rouletteConfig',
    icon: 'mdi-toolbox-outline',
  },
};
